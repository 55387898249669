import React from 'react'

import Layout from '../components/layout'
import { Container, Row, Col, Image } from 'react-bootstrap'

import { Section, SectionGray } from '../components/sections'

import mat from '../images/plan/mat.jpg'
import implementazione from '../images/plan/Implementazione.jpg'

const Pianificazione = () => (
  <Layout frame='objNav'>

    <Section id='mat'>
        <Container>
            <Row>
                <Col md={12} className='text-center heading-section'>
                    <h3>Livello maturità SIGRId</h3>
                    <p>
                        L'immagine sottostante mostra il livello di maturità attuale di SIGRId, attualmente il
                        livello è parziale/ standardizzato.
                    </p>
                </Col>
            </Row>
        </Container>

        <Container>
            <Row className='row-bottom-padded-md'>
                <Col md={12} className='text-center'>
                    <Image src={mat} fluid />
                </Col>
            </Row>
        </Container>
    </Section>

    <SectionGray id='impl'>
        <Container>
            <Row>
                <Col md={12} className='text-center heading-section'>
                    <h3>Implementazione SIGRId</h3>
                    <p>
                        L'implementazione di un sistema di controllo interno prevede quattro fasi fondamentali.
                    </p>

                    <p>
                        Durante la prima fase bisogna valutare, se esiste, lo stato attuale dello SCI. Dopo la fase di verifica
                        si passa alla pianificazione dell'implementazione. A quest'ultima fase fa seguito l'effettiva implementazione dello SCI
                        e da ultimo l'applicazione dello SCI.
                    </p>

                    <p>
                        Attualmente ci si trova nella fase 2, la fase relativa all'implementazione del sistema di controllo interno.
                    </p>
                </Col>
            </Row>
        </Container>

        <Container>
            <Row className='row-bottom-padded-md'>
                <Col md={12} className='text-center'>
                    <Image src={implementazione} fluid />
                </Col>
            </Row>
        </Container>
    </SectionGray>

  </Layout>

)

export default Pianificazione
